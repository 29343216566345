<template>
  <div>
    <section>
      <el-result icon="info" title="Work in Progress" subTitle="This part is not completed yet.">
      </el-result>
    </section>
  </div>
</template>

<script>
import AdvanceTable from "../components/advanceTable/advanceTable.vue";
import vSelect from "vue-select";
import Prompt from "@/views/erp/mixins/Prompt";

export default {
  mixins: [Prompt],
  components: {
    AdvanceTable,
    vSelect
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  mounted() {
  },
  methods: {
    //
  }
};
</script>

<style scoped>
.pipeline .card-body {
  padding: 0.5rem 1rem;
}
</style>
